<template>
  <div class="row row-equal">
    <div class="flex xl6 xs12">
      <div class="row">
        <div
          class="flex xs12 sm4"
          v-for="(info, idx) in infoTiles"
          :key="idx"
        >
          <va-card
            class="mb-4"
            :color="info.color"
          >
            <p
              class="display-2 mb-0"
              style="color: white;"
            >
              {{ info.value }}
            </p>
            <p>{{ info.text }}</p>
          </va-card>
        </div>
      </div>

      <div class="row">
        <div class="flex xs12 md6">
          <va-card title="Past 24 Hours">
            <div class="row row-separated">
              <div class="flex xs4">
                <p
                  class="display-2 mb-1 text--center"
                  :style="{color: this.$themes.primary}"
                >
                  {{ userCount24 }}
                </p>
                <p class="text--center mb-1">
                  Users
                </p>
              </div>
              <div class="flex xs4">
                <p
                  class="display-2 mb-1 text--center"
                  :style="{color: this.$themes.info}"
                >
                  {{ compExamsCount24 }}
                </p>
                <p class="text--center no-wrap mb-1">
                  Completed<br>
                  Exams
                </p>
              </div>
              <!-- <div class="flex xs4 ">
                <p
                  class="display-2 mb-1 text-center"
                  :style="{color: this.$themes.info}"
                >
                  {{ subscriptionCount24 }}
                </p>
                <p class="text--center no-wrap mb-1">
                  Subscriptions
                </p>
              </div> -->
            </div>
          </va-card>
        </div>
        <div class="flex xs12 md6">
          <va-card title="All Time">
            <va-button
                icon="fa fa-spinner"
                outline
                small
                slot="actions"
                class="mr-0"
                @click="refreshCompletedExamsData(false)"
              >
              Refresh 
            </va-button>
            <div class="row row-separated">
              <div class="flex xs4">
                <p
                  class="display-2 mb-1 text-center"
                  :style="{color: this.$themes.info}"
                >
                  {{ userCount }}
                </p>
                <p class="text--center mb-1">
                  Users
                </p>
              </div>
              <div class="flex xs4">
                <p
                  class="display-2 mb-1 text-center"
                  :style="{color: this.$themes.info}"
                >
                  {{ compExamsCount }}
                </p>
                <p class="text--center no-wrap mb-1">
                  Completed<br>
                  Exams
                </p>
              </div>
            </div>
          </va-card>
        </div>
      </div>
    </div>

    <div class="flex xs12 md6 xl3">
      <va-card
        title="Quick Links"
      >
        <div class="row mt-3">
          <va-button color="primary">
            <router-link class="text-white"  to="/users/findusers">
              Find User
            </router-link>
          </va-button>
          <va-button color="primary">
            <router-link class="text-white"  to="/tickets/evaluation-tickets">
              My Tickets
            </router-link>
          </va-button>
          <va-button color="primary">
            <router-link class="text-white"  :to="'/users/edituser/' + $store.state.app.user.uid">
              My User Account
            </router-link>
          </va-button>
        </div>
      </va-card>
    </div>
    <div class="flex xs12 md6 xl3">
      <va-card
        stripe="info"
        title="Developer Github"
      >
        For any developers needing to view our GitHub, once granted access please click the link below

        <div class="row mt-3">
          <va-button
            color="primary"
            target="_blank"
            href="https://github.com/ApexInnovationsOrg/NIHSSWebsite"
          >
            View GitHub Repo
          </va-button>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase';
import axios from 'axios';
import githubKey from './ContributersGithubKey.json';

export default {
  name: 'DashboardInfoBlock',
  data() {
    return {
      newUsers: [],
      recentExams: [],
      userCount: 0,
      compExamsCount: 0,
      subscriptionCount: 0,
      userCount24: 0,
      compExamsCount24: 0,
      subscriptionCount24: 0,
      infoTiles: [{
        color: 'success',
        value: 0,
        text: 'Exams',
        icon: '',
      }, {
        color: 'danger',
        value: 0,
        text: 'Models',
        icon: '',
      }, {
        color: 'warning',
        value: 0,
        text: 'Unclaimed Tickets',
        icon: '',
      }],
      modal: false,
      totalCommits: 0,
      examsWeCareAbout: ['Beta 2', 'Apex Testing', 'Apex Testing ', 'NIHSS Beta'],
      unclaimedTickets: 0,
    };
  },
  methods: {
    showModal() {
      this.modal = true;
    },
    async getExamCount() {
      await firebase.firestore().collection('Test').where('Active', '==', true).where('Deleted', '==', false).get().then((snapshot)=>{
        this.infoTiles[0].value = snapshot.docs.length;
      });
    },
    async refreshCompletedExamsData(fromCache) {
      try {
        let response = 0;
        if(fromCache){
          response = await firebase.firestore().collection('charts').doc('admin').get();
          let data = response.data();
          response = data.completedExamsCount;
        }else{
          const snapshot = await firebase.firestore().collection('completedExams').where('Test.Name', 'not-in', examsWeCareAbout).get();
          await firebase.firestore()
          .collection('charts')
          .doc('admin')
          .update({
            completedExamsCount: snapshot.docs.length
          });
          response = snapshot.docs.length;
        }
        this.compExamsCount = response;
      } catch (error) {
        console.error('Error:', error);
      }
    },
    async getUserCount() {
      await firebase.firestore().collection('users').get().then((snapshot)=>{
        this.userCount = snapshot.docs.length;
      });
    },
    async getCompExamsCount24() {
      const event = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
      const expirationDate = firebase.firestore.Timestamp.fromDate(event);
      await firebase.firestore().collection('completedExams').where('TestCompleted', '>', expirationDate).get().then((snapshot)=>{
        this.compExamsCount24 = snapshot.docs.length;
      });
    },
    async getUserCount24() {
      const event = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
      const expirationDate = firebase.firestore.Timestamp.fromDate(event);
      await firebase.firestore().collection('users').where('creationDate', '>', expirationDate).get().then((snapshot)=>{
        this.userCount24 = snapshot.docs.length;
      });
    },
    async getModelCount() {
      await firebase.firestore().collection('model').where('Active', '==', true).where('Deleted', '==', false).get().then((snapshot)=>{
        this.infoTiles[1].value = snapshot.docs.length;
      });
    },
    async getUnclaimedTickets() {
      await firebase.firestore().collection('evalTickets').where('adminID', '==', false).get().then((snapshot)=>{
        this.unclaimedTickets = snapshot.docs.length;
        this.infoTiles[2].value = snapshot.docs.length;
      });
    },
    async loadGithubData() {
      const {data} = await axios.get('https://api.github.com/repos/ApexInnovationsOrg/NIHSSWebsite/contributors', {
        headers: {
          'Authorization': 'token ' + githubKey.token,
          'Content-Type': 'application/json',
        },
      });
      this.contributors = data;
      this.contributors.map((contributor)=> {
        this.totalCommits += contributor.contributions;
      });
    },

  },
  async mounted() {
    await this.getUserCount();
    await this.getUserCount24();
    await this.getCompExamsCount24();
    await this.getExamCount();
    await this.getModelCount();
    await this.loadGithubData();
    await this.getUnclaimedTickets();
    await this.refreshCompletedExamsData(true);
    console.log('fetched')
  },
};
</script>

<style lang="scss" scoped>
  .row-separated {
    .flex + .flex {
      border-left: 1px solid #e3eaeb;
    }

    @include media-breakpoint-down(xs) {
      p:not(.display-2) {
        font-size: 0.875rem;
      }
    }
  }

  .text-white {
    color: white;
  }

  .dashboard {
    .va-card__header--over {
      @include media-breakpoint-up(md) {
        padding-top: 0 !important;
      }
    }

    .va-card__image {
      @include media-breakpoint-up(md) {
        padding-bottom: 0 !important;
      }
    }
  }
</style>
